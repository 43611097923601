import {
  FreeCamera,
  Vector2,
  Vector3,
  HemisphericLight,
  MeshBuilder,
  WebXRDefaultExperience,
  Color3,
  PhotoDome,
  StandardMaterial,
  WebXRFeatureName,
  Effect,
  ShaderMaterial,
  SceneLoader,
  PointLight,
} from '@babylonjs/core';
import '@babylonjs/loaders/glTF';
import { Link } from 'gatsby';
import React, { useState, useCallback } from 'react';
import '../assets/stylesheets/pages/babylon.scss';
import '../assets/stylesheets/pages/babylonjs-hand-inserted.scss';
import BabylonScene from '../components/BabylonScene/BabylonScene';

let box;
let can;
let logo;

export default function LongsliceVR() {
  const [rotationSpeed, setRotationSpeed] = useState(10);

  const onSceneReady = useCallback(async (scene) => {
    // This creates and positions a free camera (non-mesh)
    const camera = new FreeCamera('camera1', new Vector3(0, 5, -10), scene);
    // This targets the camera to scene origin
    camera.setTarget(Vector3.Zero());
    const canvas = scene.getEngine().getRenderingCanvas();
    // This attaches the camera to the canvas
    camera.attachControl(canvas, true);
    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    const light = new HemisphericLight('light', new Vector3(0, 1, 0), scene);
    // Default intensity is 1. Let's dim the light a small amount
    light.intensity = 1.5;
    // Our built-in 'box' shape.
    box = MeshBuilder.CreateBox('box', { size: 2 }, scene);
    // Move the box upward 1/2 its height
    box.position.y = 1;

    const pinkPointLight = new PointLight(
      'light',
      new Vector3(-3, -10, 0),
      scene
    );
    pinkPointLight.diffuse = new Color3(1, 0, 0.95);
    pinkPointLight.specular = new Color3(0, 0.88, 1);
    pinkPointLight.intensity = 1.2;

    const bluePointLight = new PointLight(
      'light',
      new Vector3(3, 10, 0),
      scene
    );
    bluePointLight.specular = new Color3(1, 0, 0.95);
    bluePointLight.diffuse = new Color3(0, 0.88, 1);
    bluePointLight.intensity = 1.2;

    // SceneLoader.Append(`/3D/longslice/`, 'can.glb', scene, (passedScene) => {
    //   console.log('loaded a thing', passedScene);
    // });

    SceneLoader.LoadAssetContainerAsync(
      `/3D/longslice/`,
      'can.glb',
      scene
    ).then((container) => {
      console.log('The object and its pieces', container);
      can = container.createRootMesh();
      container.addAllToScene();
      can.position.y = 2;
      can.scaling.x = 5;
      can.scaling.y = 5;
      can.scaling.z = 5;
    });

    SceneLoader.LoadAssetContainerAsync(
      `/3D/longslice/`,
      'logo.glb',
      scene
    ).then((container) => {
      console.log('The object and its pieces', container);
      logo = container.createRootMesh();
      console.log(logo);
      container.addAllToScene();
      logo.position.y = 12;
      logo.position.z = 8;
      logo.rotation.z = Math.PI / 2;
      logo.rotation.y = Math.PI / -2;
      logo.rotation.x = Math.PI / 2;
    });

    // Add in the sky
    const dome = new PhotoDome(
      'stars',
      '/3D/longslice-vr/nasafakestars.jpg',
      {
        resolution: 32,
        size: 1000,
      },
      scene
    );

    dome.setDirection(new Vector3(0, -0.5, 0));

    Effect.ShadersStore.customVertexShader = `   
      precision highp float;

      // Attributes
      attribute vec3 position;
      attribute vec2 uv;

      // Uniforms
      uniform mat4 worldViewProjection;

      // Varying
      varying vec2 vUV;

      void main(void) {
        gl_Position = worldViewProjection * vec4(position, 1.0);
        vUV = uv;
      } `;

    Effect.ShadersStore.customFragmentShader = `
      uniform vec2 screenSize;
      attribute vec2 vUV; 
    
      float glowingCross(vec2 uv, float battery) {
        //vec2 size = vec2(uv.y, uv.y) * 0.02;
        vec2 size = vec2(0.02, 0.02);
        uv += vec2(0.0, 1. * (battery + 0.0001));
        uv = abs(fract(uv) - 0.5);
        vec2 lines = smoothstep(size, vec2(0.0), uv);
        lines += smoothstep(size * 4.0, vec2(0.0), uv) ;
        return clamp(lines.x + lines.y, 0.0, 2.);
      }
      
      void main () {
        vec2 st = vUV;
        
        vec3 col = vec3(0.0, 0.1, 0.2);
        vec2 tiling1 = st * 150.;
        vec2 tiling2 = st * 60.;
      
        // tiling1.y = 3.0 / (abs(st.y + 0.2) + 0.05);
        // uv.x *= uv.y * 1.0;
        float grid1 = glowingCross(tiling1, 1.0);
        // Hollow lines vs glowy lines - basically the inverse
          //col = vec3(grid1);
        col = mix(col, vec3(1.0, 0.5, 1.0), grid1);
      
        float grid2 = glowingCross(tiling2, 1.0);
        col = mix (col, vec3(0.5, 0.5, 1.0), grid2);
      
        gl_FragColor = vec4(col, 1.);
      }`;

    const shaderMaterial = new ShaderMaterial('shader', scene, {
      vertex: 'custom',
      fragment: 'custom',
    });

    // Our built-in 'ground' shape.
    // This may be the more complicated way where we need multiMaterials -

    // const ground = MeshBuilder.CreateTiledGround('ground', {
    //   subdivisions: { h: 8, width: 8 },
    // });

    const ground = MeshBuilder.CreateGround(
      'ground',
      { width: 200, height: 200 },
      scene
    );
    ground.material = shaderMaterial;

    const defaultXRExperience = await WebXRDefaultExperience.CreateAsync(
      scene,
      { floorMeshes: [ground] }
    );
    const { featuresManager } = defaultXRExperience.baseExperience;

    featuresManager.enableFeature(
      WebXRFeatureName.TELEPORTATION,
      'latest' /* or latest */,
      {
        xrInput: defaultXRExperience.input,
        // add options here
        floorMeshes: [ground],
      }
    );
    // flip to false if you only want paraboloic.
    defaultXRExperience.teleportation.straightRayEnabled = true;
    if (!defaultXRExperience.baseExperience) {
      // no xr support
      console.log("Whoa boy, XR didn't initialize properly");
    } else {
      // all good, ready to go
    }
  });
  /**
   * Will run on every frame render.  We are spinning the box on y-axis.
   */
  const onRender = useCallback(
    (scene) => {
      if (box !== undefined && can !== undefined) {
        const deltaTimeInMillis = scene.getEngine().getDeltaTime();
        const rpm = rotationSpeed;
        box.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
        can.rotation.y += (rpm / 60) * Math.PI * 2 * (deltaTimeInMillis / 1000);
      }
    },
    [rotationSpeed]
  );

  return (
    <div>
      <BabylonScene
        antialias
        onSceneReady={onSceneReady}
        onRender={onRender}
        id="my-canvas"
      />
    </div>
  );
}
